import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію | RedClay Courts
			</title>
			<meta name={"description"} content={"Підвищіть свій ігровий досвід"} />
			<meta property={"og:title"} content={"Про компанію | RedClay Courts"} />
			<meta property={"og:description"} content={"Підвищіть свій ігровий досвід"} />
			<link rel={"shortcut icon"} href={"https://radiomintim.com/img/2103694.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiomintim.com/img/2103694.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiomintim.com/img/2103694.png"} />
			<meta name={"msapplication-TileColor"} content={"https://radiomintim.com/img/2103694.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="#170f06"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про компанію
				</Text>
				<Text font="--lead" color="--light" max-width="900px">
				Ми пишаємося тим, що пропонуємо ряд виняткових послуг для покращення вашого тенісного досвіду. Наше прагнення забезпечити найкращі умови та зручності гарантує, що кожен візит на наші корти - це не просто гра, а незабутній досвід. Відкрийте для себе комплексні послуги, які ми надаємо, щоб підтримати вашу пристрасть до тенісу.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/2.jpg"
						border-radius="24px"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						display="block"
						position="static"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Випробуйте найкраще з RedClay Courts
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						У RedClay Courts ми віримо у забезпечення цілісного тенісного досвіду. Наші послуги призначені для гравців усіх рівнів, гарантуючи, що кожен знайде щось, щоб покращити свою гру та насолодитися часом, проведеним на корті. Від професійних тренерів до першокласних зручностей - ми надаємо все необхідне, щоб вивести вашу гру на новий рівень.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Професійний коучинг
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						Наші професійні тренерські послуги розроблені таким чином, щоб допомогти вам вдосконалити свої навички та підвищити рівень гри. Наші досвідчені тренери проводять індивідуальні тренування, зосереджуючись на вдосконаленні вашої техніки, стратегії та загальної продуктивності.
Приватні уроки: Індивідуальні тренування, розроблені для задоволення ваших конкретних потреб і цілей.
Групові клініки: Приєднуйтесь до занять у малих групах для цілеспрямованого навчання та розвитку навичок.
Юніорські програми: Спеціалізовані тренувальні програми для юних гравців для розвитку навичок з раннього віку.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Обслуговування кортів преміум-класу
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						Ми пишаємося тим, що утримуємо наші корти за найвищими стандартами. Наші глиняні корти регулярно перевіряються та доглядаються для забезпечення оптимальних умов гри.
Щоденне обслуговування: Наша команда виконує щоденне обслуговування, щоб підтримувати корти в ідеальному стані.
Сезонне обслуговування: Комплексне сезонне обслуговування для усунення будь-якого зносу та забезпечення довготривалої якості.
Бронювання кортів: Гнучкі варіанти бронювання відповідно до вашого розкладу, що дозволить вам грати у зручний для вас час.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Комплексні зручності
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						RedClay Courts пропонує цілий ряд зручностей, щоб зробити ваш візит комфортним і приємним. У нас є все, що вам потрібно: від зон відпочинку до основних зручностей.
Лаунж-зони: Комфортні місця для відпочинку до та після гри.
Роздягальні: Безпечні та чисті роздягальні для вашої зручності.
Pro Shop: Доступ до високоякісного тенісного спорядження та одягу.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Дізнайтеся більше про корти з покриттям RedClay
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						І це тільки початок. Щоб дізнатися більше про наш широкий спектр послуг та отримати детальну інформацію, будь ласка, зв'яжіться з нами або відвідайте наш об'єкт. Наша команда завжди готова допомогти вам і забезпечити вас усім необхідним для виняткового тенісного досвіду.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
						Приєднуйтесь до нас у RedClay Courts!
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
						Скористайтеся комплексними послугами, які роблять RedClay Courts найкращим місцем для любителів тенісу. Підвищуйте свій рівень гри, насолоджуйтеся високоякісними умовами та станьте частиною нашої динамічної тенісної спільноти. Ваш ідеальний ігровий досвід чекає на вас!
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiomintim.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});